<template>
    <div class="fade-in">
        <a
            class="portfolio-item cursor-pointer flex flex-col gap-4"
            :class="
                _portfolio.state || _portfolio['accessibility']
                    ? 'pb-0'
                    : 'pb-6'
            "
            :href="_portfolio.slug"
        >
            <div class="img-container overflow-y-hidden drop-shadow-md">
                <img
                    :src="_portfolio.image?.contentUrl"
                    :alt="_portfolio.image?.caption || 'Portfolio Bild'"
                    class="object-cover w-full"
                    :class="[
                        square ? 'aspect-square' : 'aspect-[3/2]',
                        { 'rounded-2xl': roundeCorners },
                    ]"
                />
            </div>
            <div class="flex flex-col grow">
                <div class="flex flex-wrap gap-x-4">
                    <Tag
                        v-if="_portfolio.additionalType"
                        :iconName="config.global.categoryIcon"
                        :text="_portfolio.additionalType"
                    />
                    <Tag
                        v-if="_portfolio.address?.addressLocality"
                        iconName="fa-map-pin"
                        :text="_portfolio.address?.addressLocality"
                        variant="light"
                    />
                </div>
                <div class="text-f5 pt-2 item-title">
                    {{ _portfolio.name }}
                </div>
                <div
                    v-if="_portfolio.state || _portfolio['accessibility']"
                    class="flex gap-4 pt-2 mt-auto"
                >
                    <Tag
                        v-if="_portfolio.state"
                        :iconName="stateIcons[_portfolio.state]"
                        :text="t(_portfolio.state.toLowerCase() as any)"
                        variant="state"
                        :color="stateColors[_portfolio.state]"
                    />
                    <Tag
                        v-for="a in _portfolio['accessibility']"
                        iconName="fa-wheelchair"
                        text=""
                    />
                </div>
            </div>
        </a>
    </div>
</template>

<style lang="scss" scoped>
.portfolio-item {
    .img-container,
    .item-title {
        @apply underline decoration-transparent;
        @apply transition-all duration-300;
    }

    @screen minL {
        @media (prefers-reduced-motion: no-preference) {
            &:hover {
                .item-title {
                    @apply decoration-black;
                }
                .img-container {
                    @apply scale-105;
                }
            }
        }
    }
}
</style>

<script setup lang="ts">
import { PropType, watch } from "vue";
import { IndexResponse } from "@gql-types/types.generated";
import { CONFIG_OPTIONS as config, t } from "@src/globals";
import Tag from "@src/components/common/Tag.vue";

const props = defineProps({
    portfolio: Object as PropType<IndexResponse>,
    square: { type: Boolean, default: false },
});

let _portfolio = props.portfolio as IndexResponse; // linting workaround

watch(
    () => props.portfolio,
    (newPortfolio) => {
        _portfolio = newPortfolio as IndexResponse;
    },
);

const stateColors = {
    Open: config.global.successColor,
    Closed: config.global.failureColor,
};

const roundeCorners = config.global.roundedCorners;
const stateIcons = {
    Open: config.card.stateSuccessIcon,
    Closed: config.card.stateFailureIcon,
};
</script>
